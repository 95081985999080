import React, { PropsWithChildren, useMemo } from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
import { orderBy } from "lodash";

import { TimeRangeEventsResponse } from "../../../api/grpc/insights/insights";
import { ResponsiveLine } from "@nivo/line";

import "./ChartBox.scss";

export const ChartBox = ({
  data,
}: PropsWithChildren<{
  data?: TimeRangeEventsResponse;
}>) => {
  const { timeZone } = useTimeZoneContext();

  let sortedData = useMemo(() => {
    return data?.data
      ? [
          {
            id: "events",
            color: "blue",
            data: orderBy(
              Object.entries(data.data || {}).map(
                ({ "0": key, "1": value }) => {
                  return {
                    x: utcToZonedTime(new Date(key), timeZone),
                    y: value,
                  };
                }
              ),
              [
                (item) => {
                  return format(
                    utcToZonedTime(new Date(item.x), timeZone),
                    "yyyy-MM-dd"
                  );
                },
              ],
              ["asc"]
            ).map((i) => {
              return {
                x: i.x,
                y: i.y,
              };
            }),
          },
        ]
      : [];
  }, [data]);

  return (
    <ResponsiveLine
      data={sortedData}
      colors="#156C79"
      lineWidth={1}
      margin={{ top: 20, right: 30, bottom: 80, left: 60 }}
      tooltip={({ point }) => (
        <div className="ChartBox__tooltip flex-a-center">
          <span className="ChartBox__tooltip--box"></span>
          <span>
            <strong>{point.data.xFormatted}</strong>, Meetings:{" "}
            <strong>{point.data.yFormatted}</strong>
          </span>
        </div>
      )}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        precision: "day",
        useUTC: false,
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: "linear",
        stacked: false,
      }}
      axisBottom={{
        tickSize: 0,
        format: (tick) => format(new Date(tick), "EEEE"),
        tickValues: `every day`,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 25,
        format: (e) => Math.floor(e) === e && e,
      }}
      enableGridY={false}
      enablePoints={false}
      enableArea={true}
      areaOpacity={1}
      curve="monotoneX"
      useMesh
      animate
      isInteractive
      theme={{
        textColor: "#9C9C9C",
        tooltip: {
          container: {
            borderRadius: 12,
          },
        },
      }}
      defs={[
        {
          id: "gradientC",
          type: "linearGradient",
          colors: [
            { offset: 20, color: "#13807E" },
            { offset: 100, color: "#00EA96" },
          ],
        },
      ]}
      fill={[{ match: "*", id: "gradientC" }]}
    />
  );
};
