import React, { useEffect, useMemo, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";
import { useFloorData, useMarkerData } from "./floorData";
import { useTypesHelper } from "./useTypesHelper";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useWorkplaceRequests } from "../../../api/graphql/useWorkplaceRequests";

import { Popup } from "../MarkerPopup/Popup";
import { floorPlanHelpers } from "./floorPlanHelpers";
import { getPoiIcon } from "./poiMarkersHelper";
import { FloorFields, FloorPOI } from "../../../api/graphql/floors/floors";
import { FloorChildrenType } from "../FloorChildrenList/FloorChildrenDefaultList";

export type MarkerSettings = {
  scaleFactor?: number;
  showRoomMarkers?: boolean;
  activeDraw?: boolean;
};

export enum MarkerStatuses {
  ASSIGNED = 1,
  BLOCKED = 2,
  RESERVED = 3,
  AVAILABLE = 4,
}

const weightValue = 2;
const borderColor = "#156C79";
const fillColor = "#B9D3D7";
const fillOpacity = 0.85;

export const useMarker = (
  data: FloorFields,
  setDisableZoom?: React.Dispatch<React.SetStateAction<boolean>>,
  disableZoom?: boolean,
  settings?: MarkerSettings
) => {
  const { getFloorEntityLeafletIcon, getScaledIcon } = floorPlanHelpers();

  const { roomsOfFloor, desksOfFloor, wayfindersfFloor } = useFloorData(data);
  const { user } = useAuthContext();
  const { markerData, cirleData, polygonalData } = useMarkerData(
    roomsOfFloor,
    desksOfFloor,
    wayfindersfFloor,
    settings
  );
  const { updatePOI } = useWorkplaceRequests();

  const markersCacheRef = useRef<L.Marker[]>([]);
  const circleCacheRef = useRef<L.Circle[]>([]);
  const polygonCacheRef = useRef<L.Polygon[]>([]);
  const floorPOICacheRef = useRef<L.Marker[]>([]);

  const { methodTypes } = useTypesHelper();

  const shapes = useMemo<{
    _circles: L.Circle[];
    _polygonal: L.Polygon[];
  }>(() => {
    // clear cached circles
    circleCacheRef.current.forEach((cachedCircle) => {
      cachedCircle.remove();
    });

    polygonCacheRef.current.forEach((cachedPolygon) => {
      cachedPolygon.remove();
    });

    const _circles = cirleData.flat().map((floorEntity: any) => {
      const newCircle: L.Circle = L.circle(floorEntity?.latlng, {
        color: borderColor,
        fillColor: fillColor,
        fillOpacity: fillOpacity,
        radius: floorEntity?.radius,
        weight: weightValue,
        attribution:
          floorEntity?.id + "-innerGoGetIDSplitter-" + floorEntity?.name,
      }).bindPopup(
        ReactDOMServer.renderToString(
          <Popup
            name={floorEntity?.name}
            id={floorEntity?.id}
            type={floorEntity?.type}
            location={floorEntity?.location ? floorEntity.location : ""}
            user={floorEntity?.user ? floorEntity.user : ""}
            // onClick={() => deleteMarker(floorEntity.id)}
          />
        )
      );

      return newCircle;
    });

    const _polygonal = polygonalData.flat().map((floorEntity: any) => {
      let newPolygon: L.Polygon | L.Rectangle;

      if (floorEntity.latlng[0]?.type === "polygon") {
        newPolygon = L.polygon(floorEntity?.latlng, {
          color: borderColor,
          fillColor: fillColor,
          fillOpacity: fillOpacity,
          weight: weightValue,
          attribution:
            floorEntity?.id + "-innerGoGetIDSplitter-" + floorEntity?.name,
        });
      } else {
        newPolygon = L.rectangle(floorEntity?.latlng, {
          color: borderColor,
          fillColor: fillColor,
          fillOpacity: fillOpacity,
          weight: weightValue,
          attribution:
            floorEntity?.id + "-innerGoGetIDSplitter-" + floorEntity?.name,
        });
      }

      newPolygon.bindPopup(
        ReactDOMServer.renderToString(
          <Popup
            name={floorEntity?.name}
            id={floorEntity?.id}
            type={floorEntity?.type}
            location={floorEntity?.location ? floorEntity.location : ""}
            user={floorEntity?.user ? floorEntity.user : ""}
            // onClick={() => deleteMarker(floorEntity.id)}
          />
        )
      );

      return newPolygon;
    });

    circleCacheRef.current = _circles;
    polygonCacheRef.current = _polygonal;

    return { _circles, _polygonal };
  }, [data]);

  const poiMarkers = useMemo<L.Marker[]>(() => {
    // clear cached floor POIs
    floorPOICacheRef.current.forEach((cachedPOI) => {
      cachedPOI.remove();
    });

    if (!data?.floorPOI) {
      return [];
    }

    const _poiMarkers = data.floorPOI
      ?.filter((poiMarker) => poiMarker.marker !== null)
      .map((poi: FloorPOI) => {
        const newMarker: L.Marker = L.marker(
          [poi.marker?.latitude, poi?.marker.longitude],
          {
            icon: getPoiIcon(poi.type),
            draggable: true,
            attribution: poi?.id,
            alt: "PoiMarker",
          }
        );

        newMarker.on("dragend", async (e) => {
          let { lat, lng } = e.target.getLatLng();

          await updatePOI({
            id: poi.id,
            floorId: data.id,
            marker: {
              latitude: lat === 0 ? 1 : Math.ceil(lat),
              longitude: lng === 0 ? 1 : Math.ceil(lng),
            },
          });

          return;
        });

        return newMarker;
      });

    floorPOICacheRef.current = _poiMarkers;

    return _poiMarkers;
  }, [data]);

  const markers = useMemo<L.Marker[]>(() => {
    // clear cached markers
    markersCacheRef.current.forEach((cachedMarker) => {
      cachedMarker.remove();
    });

    const _markers = markerData.flat().map((floorEntity: any) => {
      const newMarker: L.Marker = L.marker(floorEntity?.latlng, {
        icon: getFloorEntityLeafletIcon(floorEntity, "floor"),
        draggable: true,
        attribution: floorEntity?.id,
        alt: floorEntity?.type,
        title: floorEntity?.name,
      }).bindPopup(
        ReactDOMServer.renderToString(
          <Popup
            name={floorEntity?.name}
            id={floorEntity?.id}
            type={floorEntity?.type}
            location={floorEntity?.location ? floorEntity.location : ""}
            user={floorEntity?.user ? floorEntity.user : ""}
            isBlocked={floorEntity?.isBlocked}
            // onClick={() => deleteMarker(floorEntity.id)}
          />
        )
      );

      newMarker.on("dragend", (e) => {
        let { lat, lng } = e.target.getLatLng();
        let methodName =
          methodTypes[
            e.target.getIcon().options.attribution as FloorChildrenType
          ];

        methodName({
          id: e.target.getAttribution(),
          marker: {
            latitude: lat === 0 ? 1 : Math.ceil(lat),
            longitude: lng === 0 ? 1 : Math.ceil(lng),
          },
          tenantId: user?.customerid || "",
        });

        if (disableZoom === false && setDisableZoom) {
          setDisableZoom(true);
        }

        return;
      });

      return newMarker;
    });

    markersCacheRef.current = _markers;

    return _markers;
  }, [data]);

  // apply scale factor
  useEffect(() => {
    const rollback: (() => void)[] = [];

    poiMarkers.forEach((poiMarker) => {
      const currentIcon = poiMarker.getIcon();

      if (currentIcon && settings?.scaleFactor) {
        poiMarker.setIcon(
          getScaledIcon(
            currentIcon as L.Icon,
            settings.scaleFactor,
            currentIcon.options.attribution === "wayfinder"
          )
        );

        rollback.push(() => {
          poiMarker.setIcon(currentIcon);
        });
      }
    });

    markers.forEach((marker) => {
      const currentIcon = marker.getIcon();

      if (currentIcon && settings?.scaleFactor) {
        marker.setIcon(
          getScaledIcon(
            currentIcon as L.Icon,
            settings.scaleFactor,
            currentIcon.options.attribution === "wayfinder"
          )
        );

        rollback.push(() => {
          marker.setIcon(currentIcon);
        });
      }
    });

    return () => {
      rollback.forEach((f) => f());
    };
  }, [markers, settings, getScaledIcon]);

  const openMarkerPopup = (id: string) => {
    const clickedMarker = markers.find(
      (marker) => marker.options.attribution === id
    );

    if (clickedMarker) {
      if (clickedMarker.isPopupOpen()) {
        clickedMarker.closePopup();
      } else {
        clickedMarker.openPopup();
      }
    }
  };

  const openShapePopup = (id: string, type: "circle" | "polygonal") => {
    if (type === "circle") {
      const clickedCircle = shapes._circles.find(
        (circle) =>
          circle.options.attribution?.split("-innerGoGetIDSplitter-")[0] === id
      );

      if (clickedCircle) {
        if (clickedCircle.isPopupOpen()) {
          clickedCircle.closePopup();
        } else {
          clickedCircle.openPopup();
        }
      }

      return;
    }

    const clickedPolygon = shapes._polygonal.find(
      (polygon) =>
        polygon.options.attribution?.split("-innerGoGetIDSplitter-")[0] === id
    );

    if (clickedPolygon) {
      if (clickedPolygon.isPopupOpen()) {
        clickedPolygon.closePopup();
      } else {
        clickedPolygon.openPopup();
      }
    }
  };

  return {
    markers,
    poiMarkers,
    shapes,
    roomsOfFloor,
    desksOfFloor,
    wayfindersfFloor,
    openMarkerPopup,
    openShapePopup,
  };
};
