import React, { PropsWithChildren } from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { trimText } from "../../../../lib/helpers/trimText";
import { getDuration } from "../../../Bookings/lib/datePickerHelper";
import { dateToHoursAndMinutes } from "../../../Bookings/lib/dateInputConvert";
import { useInsightsContext } from "../../Context/InsightsContext";

import {
  StructureTable,
  StructureTableColumn,
} from "../../../shared/StructureTable/StructureTable";
import { RoomHistoryEventsResponse } from "../../../../api/grpc/insights/insights";
import { HistoryCheckInStatus } from "../../shared/HistoryCheckInStatus/HistoryCheckInStatus";

export const RoomHistoryTable = ({
  dataRoomHistory,
}: PropsWithChildren<{
  dataRoomHistory: RoomHistoryEventsResponse;
}>) => {
  const { timeZone } = useTimeZoneContext();
  const { customerSettings } = useInsightsContext();

  const columns: StructureTableColumn[] = [
    {
      key: "organizer",
      header: "Organizer",
    },
    {
      key: "reservationDate",
      header: "Reservation Date",
    },
    {
      key: "from",
      header: "From",
    },
    {
      key: "until",
      header: "Until",
    },
    {
      key: "duration",
      header: "Duration",
    },
    {
      key: "room",
      header: "Room",
    },
    {
      key: "checkedIn",
      header: "Checked in",
    },
  ];

  const rows = dataRoomHistory.results.map((item) => {
    const { days, hours, minutes } = getDuration(
      new Date(item.startDate),
      new Date(item.endDate)
    );

    return {
      id: item.id,
      organizer: (
        <h4>
          {item?.isFacelessEvent
            ? "On-screen reservation"
            : customerSettings?.showEventOrganizer
            ? trimText(item.organizerName, 17)
            : "N/A"}
        </h4>
      ),
      reservationDate: (
        <h4>
          {format(
            utcToZonedTime(new Date(item.startDate), timeZone),
            "yyyy-MM-dd"
          )}
        </h4>
      ),
      from: (
        <h4>{dateToHoursAndMinutes(new Date(item.startDate), timeZone)}</h4>
      ),
      until: (
        <h4>
          {dateToHoursAndMinutes(new Date(item.endDate), timeZone)}
          {days > 0 && (
            <span>, {format(new Date(item.endDate), "yyyy-MM-dd")}</span>
          )}
        </h4>
      ),
      duration: (
        <h4>
          {`${days ? `${days}d ` : ""}${hours ? `${hours}h ` : ""}${
            minutes ? `${minutes}m` : ""
          }`}
        </h4>
      ),
      room: <h4 title={item.roomsName}>{trimText(item.roomsName, 15)}</h4>,
      checkedIn: <HistoryCheckInStatus checkedIn={item.checkedIn} />,
    };
  });

  return (
    <div className="PeopleTable">
      <StructureTable
        columns={columns}
        rows={rows}
        small
        className="StructureTable--rooms InsightsHistoryTable mt-5"
      />
    </div>
  );
};
